/* eslint-disable @next/next/no-img-element */
"use client";
import Slider, {Settings} from "react-slick";
import PublicIcons from '@/components/basic/PublicIcon';
import React, {createContext, useContext, useMemo, useState} from 'react';

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./custom-carousel.css";
import cn from '@/libs/cn';
import styles from '@/components/Carousel/styles.module.scss';

interface CarouselContext {
  hideArrows: boolean;
  hover: boolean;
}

export const CarouselContext = createContext<CarouselContext>({
  hideArrows: true,
  hover: false,
});

const useCarouselContext = () => {
  return useContext(CarouselContext);
};

interface ArrowProps {
  onClick?: () => void;
}

const PrevArrow = (props: ArrowProps) => {
  const { onClick } = props;
  const { hover, hideArrows } = useCarouselContext();
  return (
    <div
      className={cn(
        styles.arrow,
        styles.prev,
        hideArrows && styles.hide,
        hideArrows && hover && styles.show,
      )}
      onClick={onClick}
    >
      <div>
        <PublicIcons name="arrowShortLeftGrey" width={24} height={24} />
      </div>
    </div>
  );
}

const NextArrow = (props: ArrowProps) => {
  const { onClick } = props;
  const { hover, hideArrows } = useCarouselContext();
  return (
    <div
      className={cn(
        styles.arrow,
        styles.next,
        hideArrows && styles.hide,
        hideArrows && hover && styles.show,
      )}
      onClick={onClick}
    >
      <div>
        <PublicIcons name="arrowShortRightGrey" width={24} height={24} />
      </div>
    </div>
  );
}

interface CustomCarouselSlick extends Settings {
  className?: string;
  classNameArrow?: string;
  minHeight?: number;
  borderRadius?: number;
  hideArrows?: boolean;
  forwardRef?: any;
  shortArrow?: boolean;
}

const CustomCarouselSlick = (props: CustomCarouselSlick) => {
  const {
    children,
    className,
    classNameArrow,
    minHeight,
    autoplay = true,
    infinite = true,
    speed = 500,
    autoplaySpeed = 5000,
    waitForAnimate = false,
    borderRadius,
    dots = true,
    shortArrow,
    hideArrows = true,
    forwardRef,
    ...rest
  } = props;

  const [hover, setHover] = useState(false);

  const contextValue = useMemo(() => ({
    hover,
    hideArrows,
  }), [hideArrows, hover]);

  return (
    <CarouselContext.Provider value={contextValue}>
      <div
        className={cn(styles.CustomCarousel, className)}
        style={{ height: minHeight, borderRadius }}
        onMouseEnter={() => setHover(true)}
        onMouseLeave={() => setHover(false)}
      >
        <Slider
          {...rest}
          autoplay={autoplay}
          infinite={infinite}
          speed={speed}
          autoplaySpeed={autoplaySpeed}
          dots={dots}
          nextArrow={<NextArrow />}
          prevArrow={<PrevArrow />}
          ref={forwardRef}
        >
          {children}
        </Slider>
      </div>
    </CarouselContext.Provider>

  );
}

export default React.forwardRef((props: CustomCarouselSlick, ref) => <CustomCarouselSlick {...props} forwardRef={ref} />);

